import React from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Button from '@mui/material/Button';
import "./InfoModal.css";

export default function AboutInfoModal ({modalIsOpen, toggleModal}) {
  return (
    <Modal
      isOpen={modalIsOpen}
      toggle={() => toggleModal(!modalIsOpen)}
      size="lg"
      style={{
        marginTop: '10em',
      }}
    >
      <ModalHeader toggle={() => toggleModal(!modalIsOpen)}>
        HOW TO PLAY
      </ModalHeader>
      <ModalBody>
        <Row className="row">
          <Col sm="12" className="headerContainer">
            <div className="flex-child">
              <h2 className="headers">DIMENSCHEN</h2>
              <p className="descriptionBody">Dimenschen is an on-chain epistemology protocol that serves as a permanent record of all possible thoughts and various types of group analysis of those thoughts, stored on the Ethereum blockchain.</p>
                <br />
              <h3 className="headers">THE LIBRARY OF BABEL</h3>
              <p className="descriptionBody">In Dimenschen, a $BABEL is any collection of valid letters, numbers, and punctuation, 347 characters or less in length. The act of minting a $BABEL is to catalog one of the unimaginably vast numbers of thoughts in The Library of Babel.</p>
                <br />
              <h5 className="headers">RULES</h5>
              <ol>
                <li className="instructions">Each $BABEL can be minted once and only once.</li>
                <li className="instructions">A $BABEL mint costs 0.01Ξ.</li>
                <li className="instructions">A $BABEL cannot be more than 347 characters in length.</li>
                <li className="instructions">The fifty-two valid orthographic symbols are abcdefghijklmnopqrstuvwxyz0123456789 ',-.:;#$%()*+/=</li>
                <li className="instructions">Uppercase characters will be automatically lowercased.</li>
                <li className="instructions">$BABEL owners earn a portion of the fees generated when users create derivative analysis tokens of their $BABEL. (See $TRUTH)</li>
              </ol>
                <br />
              <h3 className="headers">INQUISITORS OF THE LIBRARY</h3>
              <p className="descriptionBody">Inquisitors of the Library of Babel browse the $BABEL cataloged and conduct different kinds of on-chain analysis.</p>
                <br />
              <p className="descriptionBody">TYPES OF ANALYSIS</p>
                <br />
              <h5 className="headers">$TRUTH</h5>
              <ol>
                <li className="instructions">For each $BABLE minted, 100 corresponding $TRUTH tokens are immediately released to be minted.</li>
                <li className="instructions">The holder of each $TRUTH token will assign any number from 0%-100% to represent how true the $BABEL is to them.</li>
                <li className="instructions">This truth score may be updated at any time in the future by the token owner.</li>
                <li className="instructions">Both $TRUTH mints and updates cost 0.01Ξ.</li>
                <li className="instructions">75% of this fee is claimable by the owner of the $BABEL.</li>
                <li className="instructions">Wallets are blocked from minting multiple $TRUTHs for the same $BABEL. However, wallets are free to collect multiple $TRUTH tokens for the same $BABEL on secondary markets.</li>
              </ol>
                <br />
              <h5 className="headers">Future modes of analysis TBD</h5>
              <br />
                <p className="warning">This is a philosophical thesis on the nature of information and truth expressed in solidity. $BABEL and $TRUTH do not have any inherent value. These contracts have not been professionally audited, use them at your own risk.</p>
            </div>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter style={{ textAlign: 'center' }}>
        <Button 
          sx={{
            backgroundColor: "#20d1cc",
            color: "#000033",
              ':hover': {
              bgcolor: '#20d1cc',
            }
          }}
          onClick={() => toggleModal(!modalIsOpen)}
        >
          Begin
        </Button>
      </ModalFooter>
    </Modal>
  );
}
