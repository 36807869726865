import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  address: null,
  ensName: null,
  ensAvatar: null,
  // dimesBalance: null,
  // pendingDimesRewards: null,
  pendingETHRewards: null,
  babels: null,
  truths: null,
}

export const userSlice = createSlice({
  name: 'user',
  initialState: {...initialState},
  reducers: {
    assignUser: (state, action) => {
      Object.keys(action.payload).forEach(key => {
        const value = action.payload[key];
        state[key] = value;
      });
    },
    resetUser: (state) => {
      return state = {...initialState};
    }
    // resetUser: (state, action) => {
    //   console.log({state, action});
    //   return state = {
    //     address: null,
    //     ensName: null,
    //     ensAvatar: null,
    //     dimesBalance: null,
    //     pendingRewards: null,
    //     babels: null,
    //     truths: null,
    //   };
      // console.log({state});
    // }
  },
})

// Action creators are generated for each case reducer function
export const { 
  assignUser,
  resetUser,
} = userSlice.actions;

export default userSlice.reducer;