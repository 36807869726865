import React from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { ethers } from 'ethers';
import numeral from 'numeral';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import "./InfoModal.css";

export default function RewardsInfoModal ({modalIsOpen, toggleModal, claimRewards, rewardsClaimPending}) {
  const { user } = useSelector((state) => state);
  const { address, pendingETHRewards } = user;
  //V1
  // show current balance of Dimes in Wallet
  // show current rewards that are pending to be claimed
  // allow user to claim rewards
  // total $Dimes

  // FUTURE
  // link to liquidity pool 
  // staking + rewards

  // let balance = 0;
  let ethBigNumber = 0;
  
  if (address) {
    // const pendingBigNumber = ethers.BigNumber.from(pendingDimesRewards);
    // const dimesBigNumber = ethers.BigNumber.from(dimesBalance);
    // const combinedTotal = pendingBigNumber.add(dimesBigNumber);
    // balance = numeral(ethers.utils.formatEther(combinedTotal)).format('0,0.0000');
    ethBigNumber = ethers.BigNumber.from(pendingETHRewards);
  }


  return (
    <Modal
      isOpen={modalIsOpen}
      toggle={() => toggleModal(!modalIsOpen)}
      style={{
        marginTop: '10em',
      }}
    >
      <ModalHeader toggle={() => toggleModal(!modalIsOpen)} />
      <ModalBody>
      <Grid container spacing={2}>
        {/* <Grid className="type" item xs={6}>
          <div>$DIMES: </div>
        </Grid>
        <Grid className="dimes" item xs={6}>
          <div>{dimesBalance ? numeral(ethers.utils.formatEther(dimesBalance)).format('0,0.00'): null}</div>
        </Grid> */}
        {/* <Grid className="type" item xs={6}>
          <div>CLAIMABLE $DIMES: </div>
        </Grid>
        <Grid className="dimes" item xs={6}>
          <div>{pendingDimesRewards ? numeral(ethers.utils.formatEther(pendingDimesRewards)).format('0,0.00') : null}</div>
        </Grid> */}
        <Grid className="type" item xs={6}>
          <div>CLAIMABLE $ETH: </div>
        </Grid>
        <Grid className="rewards" item xs={6}>
          <div>{pendingETHRewards ? numeral(ethers.utils.formatEther(ethBigNumber)).format('0,0.0000'): null}</div>
        </Grid>
      </Grid>
      <div style={{ textAlign: 'center', margin: '2em' }}>
        {
          rewardsClaimPending
          ?
          <div style={{ padding: '3em' }}>
            <CircularProgress />
          </div>
          :
          <Button 
            sx={{
              backgroundColor: "#20d1cc",
              cursor: pendingETHRewards > 0 ? 'pointer' : 'not-allowed',
              color: "#000033",
                ':hover': {
                bgcolor: '#20d1cc',
              }
            }}
            onClick={pendingETHRewards > 0 ? () => claimRewards() : null}
          >
            Claim
          </Button>
        }
      </div>
      </ModalBody>
    </Modal>
  );
}
