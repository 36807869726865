import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import "./Babel.css";

import { abbreviateAddress } from '../helpers/user';
import { TRUTH_OS_SLUG, OS_BASE, BABEL_OS_SLUG, ICON, BABEL_ADDRESS } from '../helpers/config';

export default function Babel({babel, mintTruth, updateTruth}) {
  const { truthBabelMap } = useSelector((state) => state.truth);
  const { address } = useSelector((state) => state.user);
  const { maxTruthsPerBabel } = useSelector((state) => state.contract);
  const [value, setValue] = React.useState(50);
  const TRUTHSEARCH = `https://opensea.io/assets/${TRUTH_OS_SLUG}?search[stringTraits][0][name]=BABEL%20ID&search[stringTraits][0][values][0]=${babel.ID}&search[sortAscending]=true&search[sortBy]=PRICE`;

  useEffect(async () => {
    if (truthBabelMap[babel.ID]) {
      const usersRating = parseInt(truthBabelMap[babel.ID].attributes[1].value);
      setValue(usersRating);
    }
  }, [truthBabelMap])

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const {
    truthIdTotalPoints,
    truthIdRatingCount,
    avgTruthForBabel,
  } = babel.truthSummary;

  const truthAvg = truthIdRatingCount > 0 ? avgTruthForBabel : 50;
  const avatar = babel.owner.avatar || ICON;
  const usersRating = truthBabelMap[babel.ID] ? parseInt(truthBabelMap[babel.ID].attributes[1].value) : null;
  const usersTruthID = truthBabelMap[babel.ID] ? parseInt(truthBabelMap[babel.ID].ID) : null;

  let differenceInGlobalRating = 0;

  // user has moved the toggle from their existing rating
  if (usersRating !== null && usersRating !== value) {
    // subtract usersRating from totalpoints
    const subtractTotalPoints = truthIdTotalPoints - usersRating;
    // add value to total points
    const newTotalPoints = subtractTotalPoints + value;
    const newGlobalAvg = parseFloat(newTotalPoints / truthIdRatingCount).toFixed(2);
    // find difference between truthAvg and truthAvg using new values 
    const differenceInAvg = parseFloat(truthAvg - newGlobalAvg).toFixed(2) * -1;
    differenceInGlobalRating = differenceInAvg;
  }

  // user has not rated, but has modified the slider
  if (usersRating === null && value !== 50) {
    // add 1 to rating count
    const newRatingsCount = truthIdRatingCount + 1;
    // add value to totalpoints
    const newTotalPoints = truthIdTotalPoints + value;
    // calculate new global average
    const newGlobalAvg = parseFloat(newTotalPoints / newRatingsCount).toFixed(2);
    // assign number to difference between global ratings
    const differenceInAvg = (truthAvg - newGlobalAvg) * -1;
    differenceInGlobalRating = differenceInAvg;
  }

  const mintButton = usersRating === null ? () => mintTruth(babel.ID, value) : () => updateTruth(usersTruthID, value);

  return (
    <div className="container">
      <Container maxWidth="xl" sx={{ backgroundColor: "#000033" }}>
        <Toolbar disableGutters >
          <IconButton sx={{ p: 2 }} onClick={() => openInNewTab(`https://opensea.io/${babel.owner.address}/${BABEL_OS_SLUG}?search[sortBy]=LAST_TRANSFER_DATE`)}>
            <Avatar alt="User" src={avatar} />
          </IconButton>
          <p onClick={() => openInNewTab(`https://opensea.io/${babel.owner.address}/${BABEL_OS_SLUG}?search[sortBy]=LAST_TRANSFER_DATE`)} className="username">{babel.owner.ens || abbreviateAddress(babel.owner.address)}</p>
          <Box sx={{ flexGrow: 0, flex: 1 }}>
            <IconButton onClick={() => openInNewTab(`${OS_BASE}/assets/${BABEL_ADDRESS}/${babel.ID}`)} sx={{ float: 'right' }} aria-label="Example">
              <Icon sx={{ color: "white" }}>open_in_new</Icon>
            </IconButton>
          </Box>
        </Toolbar>
      </Container>
      <p className="text">{babel.name}</p>
      <p className="value">{!usersRating && value === 50 ? '?' : value} <span className="subtext">% true</span></p>
      <Box sx={{ width: '90%', paddingLeft: '10%' }}>
        <Grid container alignItems="center">
          <Grid item xs>
            <Slider
              value={typeof value === 'number' ? value : 0}
              onChange={handleSliderChange}
              aria-labelledby="input-slider"
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <IconButton onClick={() => openInNewTab(TRUTHSEARCH)} sx={{ textAlign: 'center' }} aria-label="Example">
          <Icon sx={{ color: "white" }}>public</Icon>
          <Typography sx={{ color: 'white', paddingLeft: '0.5em', paddingRight: '0.5em' }}>{`${truthAvg}%`}</Typography>
          <Typography sx={{ color: differenceInGlobalRating >= 0 ? '#20d1cc' : 'red' , paddingRight: '0.5em' }}>{`${differenceInGlobalRating >= 0 ? '+' : ''} ${parseFloat(differenceInGlobalRating).toFixed(2)}%`}</Typography>
          <Icon sx={{ color: "white" }}>public</Icon>
        </IconButton>
      </Box>
      <Box sx={{ flexGrow: 0, flex: 1 }}>
        <Button
          sx={{ 
            backgroundColor: "#20d1cc",
            color: "#000033",
            ':hover': { 
              bgcolor: '#20d1cc',
              cursor: !address ? 'not-allowed' : 'pointer',
            },
            margin: '1.5em',
          }} 
          onClick={!address ? null : mintButton}  
          variant="contained"
        >
          {
            usersRating !== null
            ?
            'UPDATE TRUTH'
            :
            'MINT TRUTH'
          }
        </Button>
      </Box>
      <Box>
        <Typography sx={{ paddingBottom: '1em', color: 'white' }}>{`${maxTruthsPerBabel - truthIdRatingCount} remaining`}</Typography>
      </Box>
    </div>
  );
}
