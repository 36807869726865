import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  babelList: [],
  babelMap: {},
}

export const babelSlice = createSlice({
  name: 'babel',
  initialState: {...initialState},
  reducers: {
    assignBabelData: (state, action) => {
      const mapClone = {...state.babelMap};
      const newIds = action.payload.list.map(i => i.ID); 
      state.babelList = newIds;
      action.payload.list.forEach(i => {
        mapClone[i.ID] = i;
      });
      state.babelMap = mapClone;
    },
    resetBabelData: (state) => {
      return state = {...initialState};
    }
  },
})

// Action creators are generated for each case reducer function
export const { 
  assignBabelData,
  resetBabelData,
} = babelSlice.actions;

export default babelSlice.reducer;