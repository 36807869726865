import React from "react";
import { useSelector } from 'react-redux'
import Button from '@mui/material/Button';
import "./Babel.css";

export default function CreateBabel({mintBabel, validBabel, validateBabel}) {
  const { user } = useSelector((state) => state);
  const { address } = user;

  return (
    <div className="container">
      <textarea 
        className="textarea" 
        placeholder="enter a babel to catalog"
        type="textarea"
        id="proposition"
        name="proposition"
        onChange={(e) => validateBabel(e)} 
        rows="4"
      />
      <Button
        sx={{ 
          backgroundColor: "#20d1cc",
          color: "#000033",
          ':hover': { 
            bgcolor: '#20d1cc',
            cursor: !address || validBabel !== 'Babel can be created' ? 'not-allowed' : 'pointer',
          },
          margin: '1.5em',
        }} 
        onClick={!address ? null : () => mintBabel()} variant="contained"
      >
          MINT BABEL
      </Button>
      {
        validBabel === 'Babel can be created' 
        ? 
        null
        : 
        <p style={{ textAlign: 'center', fontSize: '0.65em', color: validBabel === 'Babel can be created' ? '#20d1cc' : '#FFBF00' }}>{validBabel}</p>
      }
    </div>
  );
}