import React from 'react';
import { useSelector } from 'react-redux';
import { ethers } from 'ethers';
import numeral from 'numeral';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import { abbreviateAddress } from '../helpers/user';
import { ICON, BABEL_OS_SLUG, TRUTH_OS_SLUG } from '../helpers/config';
import "./Menu.css";

export default function MenuComponent ({ 
  connectWallet,
  loadingWallet,
  disconnectWallet,
  aboutModalIsOpen,
  toggleAboutModal,
  rewardsModalIsOpen,
  toggleRewardsModal,
}) {
  const { user } = useSelector((state) => state);
  const { address, ensName, ensAvatar, pendingETHRewards } = user;

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const pages = !address ? ['How To Play'] : ['How To Play', 'My BABEL', 'My TRUTH', 'Rewards'];
  let balance = 0;
  
  if (address) {
    balance = numeral(ethers.utils.formatEther(pendingETHRewards)).format('0,0.0000');
  }

  const onClickMap = {
    'How To Play': () => toggleAboutModal(!aboutModalIsOpen),
    'My BABEL': () => openInNewTab(`https://opensea.io/${address}/${BABEL_OS_SLUG}?search[sortBy]=LAST_TRANSFER_DATE`),
    'My TRUTH': () => openInNewTab(`https://opensea.io/${address}/${TRUTH_OS_SLUG}?search[sortBy]=LAST_TRANSFER_DATE`),
    'Rewards': () => toggleRewardsModal(!rewardsModalIsOpen),
  }

  return (
    <AppBar position="static" sx={{ backgroundColor: "#000033", borderRadius: '0.25em' }}>
      <Container maxWidth="xl" >
        <Toolbar disableGutters >
          <IconButton sx={{ p: 2 }}>
            <Avatar sx={{ height: '4em', width: '4em' }} alt="ICON" src={ICON} />
          </IconButton>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={onClickMap[page]}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {
                  address && page === 'Rewards'
                  ?
                  <Typography textAlign="center">{
                    `${page}: `} <span className="rewards">{`${balance}Ξ`}</span>
                  </Typography>
                  :
                  <Typography textAlign="center">{page}</Typography>
                }
              </Button>
            ))}
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            {
              address
              ?
              <Tooltip title="Logout">
                <IconButton onClick={() => disconnectWallet()} sx={{ p: 0 }}>
                  {
                    ensAvatar
                    ?
                    <Avatar sx={{ width: '3em', height: '3em' }} alt="Remy Sharp" src={ensAvatar || ICON} />
                    :
                    null
                  }
                  <p style={{ fontSize: '0.8em', paddingLeft: '0.5em', paddingTop: '0.5em', color: 'white' }}>{ensName || abbreviateAddress(address)}</p>
                </IconButton>
              </Tooltip>
              :
              <Tooltip title="Connect Wallet">
                <Button sx={{ 
                  backgroundColor: "#20d1cc",
                  color: "#000033",
                  ':hover': {
                    bgcolor: '#20d1cc',
                  }
                }} onClick={() => connectWallet()} variant="contained">{loadingWallet ? 'Connecting...': 'Connect'}</Button>
              </Tooltip>
            }
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
