import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  truthMap: {},
  truthBabelMap: {},
}

export const truthSlice = createSlice({
  name: 'truth',
  initialState: {...initialState},
  reducers: {
    assignTruthData: (state, action) => {
      // assign ids to list
      // assign objects to map
      // update skip
      // probably need to add 
      const mapClone = {...state.truthMap};
      const truthBabelMapClone = {...state.truthBabelMap};
      action.payload.list.forEach(i => {
        mapClone[i.ID] = i;
        truthBabelMapClone[i.attributes[0].value] = i;
      });
      state.truthMap = mapClone;
      state.truthBabelMap = truthBabelMapClone;
    },
    resetTruthData: (state) => {
      return state = {...initialState};
    }
  },
})

// Action creators are generated for each case reducer function
export const { 
  assignTruthData,
  resetTruthData,
} = truthSlice.actions;

export default truthSlice.reducer;