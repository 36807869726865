
module.exports = {
  ICON: 'https://dimenschen-profile-images.s3-us-west-2.amazonaws.com/D_Site_Icon+2.gif',
  ETHERSCAN: 'https://etherscan.io/tx/',
  BABEL_LIMIT: 5,
  TRUTH_ADDRESS: '0x968F068d24A60998C99C07bf7fcBAfC55B688491',
  BABEL_ADDRESS: '0x46BeDeeE782C3B9dA4be9e60ddFB346d60297d13',
  TRUTH_OS_SLUG: 'truth-by-dimenschen',
  BABEL_OS_SLUG: 'the-library-of-babel-by-dimenschen',
  OS_BASE: 'https://opensea.io/',
};

