

function abbreviateAddress(address) {
  // tx 
  if (address.length === 66) return address.replace(address.substring(4,62), "...");
  // address
  return address.replace(address.substring(4,38), "...");
}

module.exports = {
  abbreviateAddress,
}