import { configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/userSlice';
import contractReducer from './reducers/contractSlice';
import babelReducer from './reducers/babelSlice';
import truthReducer from './reducers/truthSlice';


export default configureStore({
  reducer: {
    user: userReducer,
    contract: contractReducer,
    babel: babelReducer,
    truth: truthReducer,
  },
});