import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  truthSaleActive: null,
  maxTruthsPerBabel: null,
  // truthMintDimesReward: null,
  // truthUpdateDimesReward: null,
  truthContractMintETHReward: null,
  truthContractUpdateETHReward: null,
  truthOwnerMintETHReward: null,
  truthOwnerUpdateETHReward: null,
  truthMintPrice: 0,
  truthUpdatePrice: 0,
  babelSaleActive: null,
  babelSupply: 0,
  babelMintPrice: 0,
}

export const contractSlice = createSlice({
  name: 'contract',
  initialState: {...initialState},
  reducers: {
    assignContractData: (state, action) => {
      Object.keys(action.payload).forEach(key => {
        const value = action.payload[key];
        state[key] = value;
      });
    },
    resetContractData: (state) => {
      return state = {...initialState};
    }
  },
})

// Action creators are generated for each case reducer function
export const { 
  assignContractData,
  resetContractData,
} = contractSlice.actions;

export default contractSlice.reducer;