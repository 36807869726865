import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import "./InfoModal.css";
import { ETHERSCAN } from '../helpers/config';

export default function PendingTransactionModal ({modalIsOpen, toggleModal, txHash, language}) {
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const txState = language.includes('success');
  return (
    <Modal
      isOpen={modalIsOpen}
      toggle={() => toggleModal(!modalIsOpen)}
      style={{
        marginTop: '10em',
      }}
    >
      <ModalHeader toggle={() => toggleModal(!modalIsOpen)} />
      <ModalBody>
        <Grid container spacing={2}>
          {
            !txState
            ?
            <Grid className="dimes" style={{ textAlign: 'center' }} item xs={12}>
              <div style={{ padding: '3em' }}>
                <CircularProgress />
              </div>
            </Grid>
            :
            <Grid className="dimes" style={{ textAlign: 'center' }} item xs={12}>
              <div style={{ padding: '3em' }}>
              <IconButton onClick={() => openInNewTab(`${ETHERSCAN}${txHash}`)} aria-label="Example">
                <Icon fontSize="large" sx={{ color: "#20d1cc" }}>check</Icon>
              </IconButton>
              </div>
            </Grid>
          }
          <Grid className="dimes" style={{ textAlign: 'center', cursor: 'pointer' }} item xs={12}>
            <div onClick={() => openInNewTab(`${ETHERSCAN}${txHash}`)}>{language}</div>
          </Grid>
        </Grid>
      </ModalBody>
    </Modal>
  );
}
