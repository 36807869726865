import React, { useState, useEffect } from 'react';
import base64 from "base-64";
import utf8 from "utf8";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { ethers } from 'ethers';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { assignUser, resetUser } from './reducers/userSlice';
import { assignContractData } from './reducers/contractSlice';
import { assignBabelData, resetBabelData } from './reducers/babelSlice';
import { assignTruthData } from './reducers/truthSlice';
import BabelContract from './contracts/BabelTokenABI.json';
import TruthContract from './contracts/TruthTokenABI.json';
import getWeb3 from "./getWeb3";
import "./App.css";

import Babel from './components/Babel';
import Menu from './components/Menu';
import CreateBabel from './components/CreateBabel';
import AboutInfoModal from './components/AboutInfoModal';
import RewardsInfoModal from './components/RewardsInfoModal';
import PendingTransactionModal from './components/PendingTransactionModal';

import { abbreviateAddress } from './helpers/user';
import { TRUTH_ADDRESS, BABEL_LIMIT } from './helpers/config';

const provider = new ethers.providers.Web3Provider(ethereum);

const whichNetwork = "mainnet"; // "rinkeby"

export default function App() {
  const { user, babel, contract } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [web3, setWeb3] = useState(null);
  const [babelContract, setBabelContract] = useState(null);
  const [truthContract, setTruthContract] = useState(null);
  const [aboutModalIsOpen, toggleAboutModal] = useState(false);
  const [rewardsModalIsOpen, toggleRewardsModal] = useState(false);
  const [rewardsClaimPending, toggleRewardsClaimPending] = useState(false);
  const [loadingWallet, updateLoadingWallet] = useState(false);
  const [pendingTXModalIsOpen, togglePendingTXModal] = useState(false);
  const [txHash, updateTxHash] = useState('');
  const [pendingLanguage, updatePendingLanguage] = useState('');
  const [pages, updatePagesCount] = useState(1);
  const [validBabel, setBabelValidity] = useState('');
  const [page, setPage] = useState(1);

  const { address } = user;
  const { babelMintPrice, babelSupply, truthMintPrice, truthUpdatePrice } = contract;
  const { babelList, babelMap } = babel;

  // get contract data
  useEffect(async () => {
    try {
      const instance = await getWeb3();
      setWeb3(instance);
      const network = instance.version.network;
      // GET DIMENSCHEN CONTRACT DETAILS
      // BABEL CONTRACT
      const babelDeployedNetwork = BabelContract.networks[whichNetwork];
      const thisBabelContract = new instance.eth.Contract(
        BabelContract.abi,
        babelDeployedNetwork && babelDeployedNetwork.address,
      );

      thisBabelContract.options.address = babelDeployedNetwork.address;
      setBabelContract(thisBabelContract);

      // TRUTH CONTRACT DETAILS
      const truthDeployedNetwork = TruthContract.networks[whichNetwork];
      const thisTruthContract = new instance.eth.Contract(
        TruthContract.abi,
        truthDeployedNetwork && truthDeployedNetwork.address,
      );
      thisTruthContract.options.address = truthDeployedNetwork.address;
      setTruthContract(thisTruthContract);
    } catch (error) {
      alert(`Connect to ethereum mainnet network and refresh the page to continue`);
      console.error({error});
    }
  }, []);

  // subscribe to events
  useEffect(async () => {

      // truth token was updated 
      // if truth token is being displayed then retrieve and refresh the token metadata
      // truthRatingUpdated(_tokenId, oldRating, _newRating)

      if (!babelContract || !truthContract) return;
      // SET LISTENERS FOR CONTRACT UPDATES
      await babelContract.events.Transfer({})
        .on('data', async event => {
            // don't do anything so feed doesn't scramble a bunch
            // updateBabelSupply();
        })
      await babelContract.events.SaleStateUpdated({})
        .on('data', async event => {
          updateBabelSaleState();
        })

      await truthContract.events.Transfer({})
      .on('data', async event => {
          // event Transfer(address indexed from, address indexed to, uint256 indexed tokenId);
          updateTruthSupply();
          updateBalances();
          // update babel level metadata
      })
      await truthContract.events.SaleStateUpdated({})
        .on('data', async event => {
          updateTruthSaleState();
        })
      // uncomment after next truth contract deployment
      await truthContract.events.userRewardsClaimed({})
        .on('data', async event => {
          // console.log('event return values', event.returnValues);
          updateBalances();
        })
  }, [babelContract, truthContract]);


  // getContractStates
  useEffect(async () => {
    if (!truthContract || !babelContract) return;
    const truthSaleActive = await truthContract.methods.saleIsActive().call();
    const maxTruthsPerBabel = await truthContract.methods.MAX_TRUTH_PER_BABEL().call();
    const truthContractMintETHReward = await truthContract.methods.contractMintEthReward().call();
    const truthContractUpdateETHReward = await truthContract.methods.contractUpdateEthReward().call();
    const truthOwnerMintETHReward = await truthContract.methods.ownerMintEthReward().call();
    const truthOwnerUpdateETHReward = await truthContract.methods.ownerUpdateEthReward().call();
    const truthMintPrice = await truthContract.methods.mintPrice().call();
    const truthUpdatePrice = await truthContract.methods.updatePrice().call();
    const babelSaleActive = await babelContract.methods.saleIsActive().call();
    const babelSupply = await babelContract.methods.totalSupply().call();
    const babelMintPrice = await babelContract.methods.mintPrice().call();

    dispatch(assignContractData({
      truthSaleActive,
      maxTruthsPerBabel,
      truthContractMintETHReward,
      truthContractUpdateETHReward,
      truthOwnerMintETHReward,
      truthOwnerUpdateETHReward,
      truthMintPrice,
      truthUpdatePrice,
      babelSaleActive,
      babelSupply,
      babelMintPrice,
    }));

    const isBabelValid = await babelContract.methods.isBabelValid(' ').call();
    setBabelValidity(isBabelValid);
  }, [truthContract, babelContract]);


  // set page count for pagination
  useEffect(async () => {
    if (!babelSupply) return;
    const overflow = babelSupply % BABEL_LIMIT === 0 ? 0 : 1;
    const fullPages = Math.floor(babelSupply / BABEL_LIMIT);
    const total = fullPages + overflow;
    updatePagesCount(total);
  }, [babelSupply]);

  async function connectWallet() {
    updateLoadingWallet(true);
    try {
      const accounts = await web3.eth.getAccounts();
      
      // build out user's Dimenschen specific metadata
      // Babels && Truths
      const address = accounts[0];
      await buildUserProfile(address);
      updateLoadingWallet(false);
    } catch (error) {
      alert(
        `Failed to connect to wallet, check your network and refresh the page`,
      );
      console.error(error);
      updateLoadingWallet(false);
    }
  }

  async function disconnectWallet() {
    dispatch(resetUser());
  }

  // load babel feed
  useEffect(async () => {
    await buildBabelDetails();
  }, [babelContract, babelSupply]);

  async function buildBabelDetails(localPage) {
    if (!babelContract || !babelSupply || babelSupply == 0) return;
    
    // for page, 
    // get babel ids for page
    // load data per
    // when page is changed, get new page of information
    let thisPage = localPage ? localPage : page;
    let offset = 1;
    offset = offset + ((thisPage - 1) * 5);

    // for each page change offset to account for change
    const IDS = [];
    while(IDS.length < BABEL_LIMIT && offset <= babelSupply) {
      const id = babelSupply - offset;
      IDS.push(id);
      offset += 1;
    }

    const babelTokenUri = await Promise.all(
      IDS.map(async id => {
        return babelContract.methods.tokenURI(parseInt(id)).call();
      })
    );

    const babelTokenOwners = await Promise.all(
      IDS.map(async id => {
        return babelContract.methods.ownerOf(parseInt(id)).call();
      })
    );
    
    const uniqueOwners = [...new Set(babelTokenOwners)];

    const ENSprofiles = await Promise.all(
      uniqueOwners.map(async owner => {
        return provider.lookupAddress(owner);
      })
    );

    const ENSMap = ENSprofiles.reduce((acc, cur, idx) => {
      acc[uniqueOwners[idx]] = cur;
      acc[cur] = uniqueOwners[idx];
      return acc;
    }, {});
  
    const uniqueENS = [...new Set(ENSprofiles)].filter(Boolean);

    const ENSavatars = await Promise.all(
      uniqueENS.map(async ens => {
        const resolver = await provider.getResolver(ens);
        return resolver.getText("avatar");
      })
    );

    const avatarMAP = ENSavatars.reduce((acc, cur, idx) => {
      acc[ENSMap[uniqueENS[idx]]] = cur;
      return acc;
    }, {});

    const metadata = await decodeBase64(babelTokenUri);

    const truthTokenUri = await Promise.all(
      IDS.map(async id => {
        return getBabelTruthData(id);
      })
    );

    const mergedBabel = metadata.map((token, idx) => {
      token.truthSummary = truthTokenUri[idx];
      token.ID  = IDS[idx];
      const address = babelTokenOwners[idx];
      token.owner = {
        address,
        ens: ENSMap[address] || null,
        avatar: avatarMAP[address] || null,
      }
      return token;
    });
  
    dispatch(assignBabelData({ list: mergedBabel }));
  }

  async function buildUserProfile(address) {
    const pendingETHRewards = await truthContract.methods.userClaimableEth(address).call();
    const babels = await babelContract.methods.tokensOfOwner(address).call();
    const truths = await truthContract.methods.tokensOfOwner(address).call();
    
    const { ensName, ensAvatar } = await getUserDetails(address);
    const truthMetadata = await getUserTruths(truths); // needs to map babel token id to rating to user

    dispatch(assignUser({
      address,
      ensName,
      ensAvatar,
      pendingETHRewards,
      babels,
      truths
    }));
    dispatch(assignTruthData({
      list: truthMetadata,
    }))
  }

  async function getUserDetails(ethAddress) {
    const ensName = await provider.lookupAddress(ethAddress);
    const resolver = !ensName ? null : await provider.getResolver(ensName);
    const ensAvatar = !resolver ? null : await resolver.getText("avatar");
    return { ensName, ensAvatar };
  }

  async function getUserTruths(truths) {
    // need a babel id for each of users truth ids
    const truthTokenUri = await Promise.all(
      truths.map(async id => {
        return truthContract.methods.tokenURI(parseInt(id)).call();
      })
    );
    
    // merge ids with correct metadata
    const metadata = await decodeBase64(truthTokenUri);

    const mergedBabel = metadata.map((token, idx) => {
      token.ID  = truths[idx];
      return token;
    });
    return mergedBabel;
  }

  /*
   * takes list of base64 items
   * returns list of decoded items
   */
  async function decodeBase64(list) {
    const metadata = list.map(token => {
      const baseDecode = base64.decode(token.split('data:application/json;base64,')[1]);
      const utf8Decode = utf8.decode(baseDecode);
      const decoded = JSON.parse(utf8Decode);
      return decoded;
    });
    return metadata;
  }

  // add pending tx UI and replace alert mechanism
  // cancel doesn't work after mint triggers for whatever reason
  async function mintBabel() {
    updateTxHash('');
    updatePendingLanguage('Please confirm the transaction with your wallet');
    togglePendingTXModal(true);
    let tempHash;
    const propositionString = document.getElementById("proposition").value;
    const res = await babelContract.methods.mint(propositionString).send({ value: babelMintPrice, from: address })
      .on('transactionHash', hash => {
        tempHash = abbreviateAddress(hash);
        updateTxHash(hash);
        updatePendingLanguage(`Transaction pending: ${abbreviateAddress(hash)}`);
      });
    document.getElementById("proposition").value = "";
    console.log({res});
    if (res.status === true) updatePendingLanguage(`Transaction successful: ${tempHash}`);
    else updatePendingLanguage(`Transaction failed: ${tempHash}`);
    await updateBabelSupply();
    await validateBabel();
  }

  async function mintTruth(babelTokenId, truthRating) {
    updateTxHash('');
    updatePendingLanguage('Please confirm the transaction with your wallet');
    togglePendingTXModal(true);
    let tempHash;
    const res = await truthContract.methods.mint(babelTokenId, truthRating).send({ value: truthMintPrice, from: address })
      .on('transactionHash', hash => {
        tempHash = abbreviateAddress(hash);
        updateTxHash(hash);
        updatePendingLanguage(`Transaction pending: ${abbreviateAddress(hash)}`);
      });
    if (res.status === true) updatePendingLanguage(`Transaction successful: ${tempHash}`);
    else updatePendingLanguage(`Transaction failed: ${tempHash}`);
    await updateTruthSupply();
    await buildUserProfile(address);
    await buildBabelDetails();
  }

  async function updateTruth(truthTokenId, truthRating) {
    updateTxHash('');
    updatePendingLanguage('Please confirm the transaction with your wallet');
    togglePendingTXModal(true);
    let tempHash;
    const res = await truthContract.methods.updateTruthRating(truthTokenId, truthRating).send({ value: truthUpdatePrice, from: address })
      .on('transactionHash', hash => {
        tempHash = abbreviateAddress(hash);
        updateTxHash(hash);
        updatePendingLanguage(`Transaction pending: ${abbreviateAddress(hash)}`);
      });
    if (res.status === true) updatePendingLanguage(`Transaction successful: ${tempHash}`);
    else updatePendingLanguage(`Transaction failed: ${tempHash}`);
    await updateTruthSupply();
    await buildUserProfile(address);
    await buildBabelDetails();

    // call tokenUri on truthTokenId and pass through to pendingTX

    // call OS api to refresh token metadata
    await refreshMarketplaceTokenUri(truthTokenId)
  }

  async function refreshMarketplaceTokenUri(id) {
    const url = `https://api.opensea.io/api/v1/asset/${TRUTH_ADDRESS}/${id}/?force_update=true`;
    const res = await axios.get(url);
    console.log({res});
  }

  async function updateBabelSupply() {
    if (!address) return;
    const babelSupply = await babelContract.methods.totalSupply().call();
    const babels = await babelContract.methods.tokensOfOwner(address).call();
    dispatch(assignContractData({babelSupply}));
    dispatch(assignUser({babels}));
  }

  async function updateBabelSaleState() {
    const babelSaleActive = await babelContract.methods.saleIsActive().call();
    dispatch(assignContractData({babelSaleActive}));
  }

  async function updateTruthSupply() {
    if (!address) return;
    const truthSupply = await truthContract.methods.totalSupply().call();
    const truths = await truthContract.methods.tokensOfOwner(address).call();
    dispatch(assignContractData({truthSupply}));
    dispatch(assignUser({truths}));
  }

  async function updateTruthSaleState() {
    const truthSaleActive = await truthContract.methods.saleIsActive().call();
    dispatch(assignContractData({truthSaleActive}));
  }

  async function updateBalances() {
    if (!address) return;
    const pendingETHRewards = await truthContract.methods.userClaimableEth(address).call();
    dispatch(assignUser({pendingETHRewards}));
  }

  // when babel transfer triggers, we need to refresh truth data for any impacted babels that are currently displayed
  // maybe this can wait until the graph?
  async function getBabelTruthData(babelTokenId) {
    const truthIdRatingCount = await truthContract.methods.truthIdRatingCount(babelTokenId).call();
    const truthIdTotalPoints = await truthContract.methods.truthIdTotalPoints(babelTokenId).call();
    const truthTokensArray = await truthContract.methods.truthIdsPerBabel(babelTokenId).call();
    const avgTruthForBabel = parseFloat(truthIdTotalPoints / truthIdRatingCount).toFixed(2);

    const decoded = {
      truthIdRatingCount: parseInt(truthIdRatingCount),
      truthIdTotalPoints: parseInt(truthIdTotalPoints),
      avgTruthForBabel,
      truthTokensArray,
    };
    return decoded;
  }

  async function claimRewards() {
    toggleRewardsClaimPending(true);
    const res = await truthContract.methods.claimRewards().send({ from: address })
      .on('error', event => {
        toggleRewardsClaimPending(false);
        return;
      });
    toggleRewardsClaimPending(false);
    // refresh data
    const pendingETHRewards = await truthContract.methods.userClaimableEth(address).call();
    dispatch(assignUser({pendingETHRewards}));
  }

  async function validateBabel() {
    const propositionString = document.getElementById("proposition").value || ' ';
    const isBabelValid = await babelContract.methods.isBabelValid(propositionString).call();
    setBabelValidity(isBabelValid);
  }

  async function handleChangePage(event, value) {
    dispatch(resetBabelData());
    await setPage(value);
    await buildBabelDetails(value);
  };

  async function refreshBabelFeed() {
    dispatch(resetBabelData());
    await buildBabelDetails();
  }

  if (isMobile) {
    alert('App is not built for mobile at this time')
    return (
      <div className="App">
        <p>Not available for Mobile at this time</p>
      </div>
    );
  }

  return (
    <div className="App">
      <div className="main">
        <div className="topnav">
          <Menu
            connectWallet={connectWallet} 
            loadingWallet={loadingWallet}
            disconnectWallet={disconnectWallet} 
            aboutModalIsOpen={aboutModalIsOpen}
            toggleAboutModal={toggleAboutModal}
            rewardsModalIsOpen={rewardsModalIsOpen}
            toggleRewardsModal={toggleRewardsModal}
          />
          <AboutInfoModal  
            modalIsOpen={aboutModalIsOpen} 
            toggleModal={toggleAboutModal}
          />
          <RewardsInfoModal  
            modalIsOpen={rewardsModalIsOpen} 
            toggleModal={toggleRewardsModal}
            claimRewards={claimRewards}
            rewardsClaimPending={rewardsClaimPending}
          />
          <PendingTransactionModal
           modalIsOpen={pendingTXModalIsOpen} 
           toggleModal={togglePendingTXModal}
           txHash={txHash}
           language={pendingLanguage}
          />
        </div>
        <div className="body">
          <div>
            <div style={{ margin: '1em' }}>
              <CreateBabel 
                mintBabel={mintBabel}
                validBabel={validBabel}
                validateBabel={validateBabel}
              />
            </div>
            <div>
              {
                page === 1 && babelList.length
                ?
                <Button
                  sx={{ 
                    backgroundColor: "#EFEFEF",
                    color: "#000033",
                    ':hover': { 
                      bgcolor: '#EFEFEF',
                      cursor: 'pointer',
                    },
                    margin: '1.5em',
                  }} 
                  onClick={() => refreshBabelFeed()} variant="contained"
                >
                    REFRESH FEED
                </Button>
                :
                null
              }
            </div>
            <div>
              {
                !babelList.length
                ?
                <div style={{ padding: '3em' }}>
                  <CircularProgress />
                  <p className="loading">loading the library of babel</p>
                </div>
                :
                babelList.map(id => {
                  const thisBabel = babelMap[id];
                  return (
                    <div key={id} style={{ margin: '1em' }}>
                      <Babel key={id} babel={thisBabel} mintTruth={mintTruth} updateTruth={updateTruth} />
                    </div>
                  )
                })                  
              }
            </div>
            <Stack spacing={2}>
              <Pagination 
                sx={{ margin: "auto" }} 
                count={pages} 
                page={page} 
                onChange={handleChangePage} 
                showFirstButton 
                showLastButton 
              />
            </Stack>
          </div>
        </div>
      </div>
    </div>
  );
}